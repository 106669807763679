import { Module } from "vuex"
import { State } from ".."
import { RESET, RESET_METRICS, SET_ACTIVEUSERID, SET_ADMINS, SET_COMPANIES } from "../mutation-types"
import { CREATE_ADMIN, CREATE_COMPANY, CREATE_USER, DELETE_COMPANY, DELETE_USER, DOWNLOAD_CERTIFICATES, GET_ADMINS, GET_COMPANIES, GET_DASHBOARDS, SELECT_USER, UPDATE_COMPANY, UPDATE_PASSWORD, UPDATE_USER } from "../action-types"
import http from "@/http"
import ICompany from "@/interfaces/ICompany"
import IUser from "@/interfaces/IUser"
import ICompanyForm from "@/interfaces/ICompanyForm"
import { NotificationType, createNotification } from "@/util/ToastNotification"
import IPasswordUpdateForm from "@/interfaces/IPasswordUpdateForm"

export interface UsersState {
    companies: ICompany[],
    admins: IUser[],
    activeUserId: string | null
}

export const getDefaultState = () => {
    return {
        companies: [],
        admins: [],
        activeUserId: null
    }
}

export const users: Module<UsersState, State> = {
    state: getDefaultState(),
    getters: {
        getCompanyById: (state) => (id: string): ICompany | undefined => {
            return state.companies.find(company => company.id === id)
        },
        getUserById: (state) => (id: string): IUser | undefined => {
            return state.companies.flatMap(company => company.users).find(user => user.id === id)
        },
        getAdminById: (state) => (id: string): IUser | undefined => {
            return state.admins?.find(user => user.id === id)
        }
    },
    mutations: {
        [RESET](state) {
            Object.assign(state, getDefaultState())
        },
        [SET_COMPANIES](state, companies: ICompany[]) {
            state.companies = companies
        },
        [SET_ADMINS](state, admins: IUser[]) {
            state.admins = admins
        },
        [SET_ACTIVEUSERID](state, activeuserid: string | null) {
            state.activeUserId = activeuserid
        }
    },
    actions: {
        [SELECT_USER]({commit, dispatch}, userId: string | null) {
            commit(SET_ACTIVEUSERID, userId)
            commit(RESET_METRICS)
            dispatch(GET_DASHBOARDS)
        },
        [GET_COMPANIES]({commit}) {
            http
                .get('company')
                .then(resp => commit(SET_COMPANIES, resp.data))
                .catch(() => createNotification(NotificationType.ERROR,
                    'Não foi possível comunicar com o servidor'))
        },
        [GET_ADMINS]({commit}) {
            http
                .get('user/admin')
                .then(resp => commit(SET_ADMINS, resp.data))
                .catch(() => createNotification(NotificationType.ERROR,
                    'Não foi possível comunicar com o servidor'))
        },
        [CREATE_COMPANY]({dispatch}, company: ICompanyForm) {
            return http
                .post('company', company)
                .then(() => dispatch(GET_COMPANIES))
        },
        [UPDATE_COMPANY]({dispatch}, company: ICompanyForm) {
            return http
                .put(`company/${company.id}`, company)
                .then(() => dispatch(GET_COMPANIES))
        },
        [DELETE_COMPANY]({dispatch}, id: string) {
            return http
                .delete(`company/${id}`)
                .then(() => dispatch(GET_COMPANIES))
        },
        [CREATE_USER]({dispatch}, user: IUser) {
            return http
                .post('user', user)
                .then(() => dispatch(GET_COMPANIES))
        },
        [CREATE_ADMIN]({dispatch}, user: IUser) {
            return http
                .post('user/admin', user)
                .then(() => dispatch(GET_ADMINS))
        },
        [UPDATE_USER]({dispatch}, user: IUser) {
            return http
                .put(`user/${user.id}`, user)
                .then(() => {
                    dispatch(GET_COMPANIES),
                    dispatch(GET_ADMINS)
                })
        },
        [DELETE_USER]({dispatch}, id: string) {
            return http
                .delete(`user/${id}`)
                .then(() => {
                    dispatch(GET_COMPANIES),
                    dispatch(GET_ADMINS)
                })
        },
        [UPDATE_PASSWORD](state, form: IPasswordUpdateForm) {
            return http
                .put('user/update-password', form)
        },
        [DOWNLOAD_CERTIFICATES](state, {id, deviceName}) {
            const uri = `company/${id}/certificate?deviceName=${deviceName}`

            return http
                    .get(uri, {
                        responseType: 'blob',
                        headers: {'Accept': 'application/zip'}
                    })
                    .then(resp => new Blob([resp.data], { type: 'application/zip' }))
        }
    }
}
