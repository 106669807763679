import IDashboard from "@/interfaces/IDashboard";
import { Module } from "vuex";
import { State } from "..";
import { SET_DASHBOARDS, EDIT_DASHBOARD, ADD_DASHBOARD, REMOVE_DASHBOARD, ADD_CHART, EDIT_CHART, REMOVE_CHART, EDIT_LAYOUT, RESET_METRICS } from "../mutation-types";
import http from "@/http";
import { CREATE_CHART, CREATE_DASHBOARD, DELETE_CHART, DELETE_DASHBOARD, GET_DASHBOARDS, UPDATE_CHART, UPDATE_DASHBOARD } from "../action-types";
import IChart from "@/interfaces/charts/IChart";
import { NotificationType, createNotification } from "@/util/ToastNotification";
import ILayout from "@/interfaces/ILayout";
import { reactive } from "vue";

export interface DashboardState {
    dashboards: IDashboard[]
}

const getDefaultState = () => {
    return reactive({
        dashboards: []
    })
}

export const dashboard: Module<DashboardState, State> = {
    state: getDefaultState(),
    getters: {
        getDashboardById: (state) => (id: string): IDashboard | undefined => {
            return state.dashboards.find(d => d.id === id)
        },
        getChartById: (state) => (id: string): IChart<any> | undefined => {
            return state.dashboards.flatMap(d => d.charts).find(c => c.id === id)
        },
        getDashboardBaseUrl: (state, getters, rootState): string => {
            let baseUrl = ''

            const userId = rootState.users.activeUserId

            if (userId) {
                baseUrl += `/${userId}`
            }

            return `${baseUrl}/dashboards`
        }
    },
    mutations: {
        [RESET_METRICS](state) {
            Object.assign(state, getDefaultState())
        },
        [SET_DASHBOARDS](state, dashboards: IDashboard[]) {
            state.dashboards = dashboards
        },
        [ADD_DASHBOARD](state, dashboard: IDashboard) {
            state.dashboards.push(dashboard)
        },
        [EDIT_DASHBOARD](state, {id, title, layout}: {id: string, title: string, layout: ILayout}) {
            const index 
                = state.dashboards.findIndex(dashboard => dashboard.id == id)
            if (title) {
                state.dashboards[index].title = title
            }
            if (layout) {
                state.dashboards[index].layout = layout
            }
        },
        [REMOVE_DASHBOARD](state, id: string) {
            state.dashboards 
                = state.dashboards.filter(dashboard => dashboard.id != id)
        },
        [ADD_CHART](state, { dashboardId, chart }: { dashboardId: string, chart: IChart<any> }) {
            const index 
                = state.dashboards.findIndex(d => d.id == dashboardId)
            state.dashboards[index].charts.push(chart)
        },
        [EDIT_CHART](state, { dashboardId, chart }: { dashboardId: string, chart: IChart<any> }) {
            const dashboardIndex 
                = state.dashboards.findIndex(d => d.id == dashboardId)
            const chartIndex 
                = state.dashboards[dashboardIndex].charts.findIndex(c => c.id == chart.id)

            state.dashboards[dashboardIndex].charts[chartIndex] = chart
        },
        [REMOVE_CHART](state, { dashboardId, id }:{ dashboardId: string, id: string }) {
            const dashboardIndex 
                = state.dashboards.findIndex(d => d.id == dashboardId)
            state.dashboards[dashboardIndex].charts
                = state.dashboards[dashboardIndex].charts.filter(c => c.id != id)
        },
        [EDIT_LAYOUT](state, { dashboardId, layout}: { dashboardId: string, layout: ILayout }) {
            const dashboardIndex 
                = state.dashboards.findIndex(d => d.id == dashboardId)
            state.dashboards[dashboardIndex].layout = layout
        }
    },
    actions: {
        [GET_DASHBOARDS]({ commit, getters }) {
            const baseUrl = getters.getDashboardBaseUrl

            http
                .get(baseUrl)
                .then(response => commit(SET_DASHBOARDS, response.data))
                .catch(() => createNotification(NotificationType.ERROR,
                    'Não foi possível comunicar com o servidor'))
        },
        [CREATE_DASHBOARD]({ commit, getters }, title: string) {
            const baseUrl = getters.getDashboardBaseUrl
            
            return http
                .post(baseUrl, {
                    title: title
                })
                .then(resp => {
                    const newDashboard: IDashboard = resp.data 
                    commit(ADD_DASHBOARD, newDashboard)
                    return newDashboard
                })
        },
        [UPDATE_DASHBOARD]({ commit, getters }, {id, title, layout}:
            {id: string, title: string, layout: ILayout}) {
            const baseUrl = getters.getDashboardBaseUrl
            
            return http
                .put(`${baseUrl}/${id}`, {
                    title: title,
                    layout: layout
                })
                .then(() => commit(EDIT_DASHBOARD, {
                    id: id,
                    title: title,
                    layout: layout
                }))
        },
        [DELETE_DASHBOARD]({ commit, getters }, id: string) {
            const baseUrl = getters.getDashboardBaseUrl
            
            return http
                .delete(`${baseUrl}/${id}`)
                .then(() => commit(REMOVE_DASHBOARD, id))
        },
        [CREATE_CHART]({ commit, getters }, { dashboardId, chart }: 
            {dashboardId: string, chart: IChart<any>}) {
            const baseUrl = getters.getDashboardBaseUrl
            
            return http
                .post(`${baseUrl}/${dashboardId}/charts`, chart)
                .then(resp => commit(ADD_CHART, { dashboardId: dashboardId, 
                    chart: resp.data }))
        },
        [UPDATE_CHART]({ commit, getters }, { dashboardId, chart }: 
            {dashboardId: string, chart: IChart<any>}) {
            const baseUrl = getters.getDashboardBaseUrl
            
            return http
                .put(`${baseUrl}/${dashboardId}/charts/${chart.id}`, chart)
                .then(() => commit(EDIT_CHART, { dashboardId, chart }))
        },
        [DELETE_CHART]({ commit, getters }, { dashboardId, id }: 
            {dashboardId: string, id: string}) {
            const baseUrl = getters.getDashboardBaseUrl
            
            return http
                .delete(`${baseUrl}/${dashboardId}/charts/${id}`)
                .then(() => commit(REMOVE_CHART, { dashboardId, id }))
        }
    }
}
