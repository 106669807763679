import IAlarmRule from "@/interfaces/IAlarmRule";
import { Module } from "vuex";
import { State } from "..";
import http from "@/http";
import { RESET_METRICS, SET_RULES } from "../mutation-types";
import { CREATE_RULE, DELETE_RULE, GET_RULES, UPDATE_RULE } from "../action-types";
import { createNotification, NotificationType } from "@/util/ToastNotification";

export interface AlarmState {
    rules: IAlarmRule[]
}

export const getDefaultState = () => {
    return {
        rules: []
    }
}

export const alarms: Module<AlarmState, State> = {
    state: getDefaultState(),
    getters: {
        getAlarmsBaseUrl: (state, getters, rootState): string => {
            let baseUrl = ''

            const userId = rootState.users.activeUserId

            if (userId) {
                baseUrl += `/${userId}`
            }

            return `${baseUrl}/alarms`
        },
        getRuleById: (state) => (id: string): IAlarmRule | undefined => {
            return state.rules.find(rule => rule.id === id)
        }
    },
    mutations: {
        [RESET_METRICS](state) {
            Object.assign(state, getDefaultState())
        },
        [SET_RULES](state, rules: IAlarmRule[]) {
            state.rules = rules
        }
    },
    actions: {
        [GET_RULES]({ commit, getters }) {
            const baseUrl = getters.getAlarmsBaseUrl

            http
                .get(`${baseUrl}/rules`)
                .then(response => commit(SET_RULES, response.data))
                .catch(() => createNotification(NotificationType.ERROR,
                    'Não foi possível comunicar com o servidor'))
        },
        [CREATE_RULE]({ dispatch, getters }, rule: IAlarmRule) {
            const baseUrl = getters.getAlarmsBaseUrl
            
            return http
                .post(`${baseUrl}/rules`, rule)
                .then(resp => {
                    dispatch(GET_RULES)
                    return resp.data
                })
        },
        [UPDATE_RULE]({ dispatch, getters }, rule: IAlarmRule) {
            const baseUrl = getters.getAlarmsBaseUrl

            return http
                .put(`${baseUrl}/rules/${rule.id}`, rule)
                .then(() => dispatch(GET_RULES))
        },
        [DELETE_RULE]({ dispatch, getters }, id: string) {
            const baseUrl = getters.getAlarmsBaseUrl

            return http
                .delete(`${baseUrl}/rules/${id}`)
                .then(() => dispatch(GET_RULES))
        }
    }
}