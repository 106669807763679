export const RESET = "RESET"
export const RESET_METRICS = "RESET_METRICS"

export const SET_AUTHORITIES = "SET_AUTHORITIES"

export const SET_DASHBOARDS = "SET_DASHBOARDS"
export const ADD_DASHBOARD = "ADD_DASHBOARD"
export const EDIT_DASHBOARD = "EDIT_DASHBOARD"
export const REMOVE_DASHBOARD = "REMOVE_DASHBOARD"

export const ADD_CHART = "ADD_CHART"
export const EDIT_CHART = "EDIT_CHART"
export const REMOVE_CHART = "REMOVE_CHART"

export const EDIT_LAYOUT = "EDIT_LAYOUT"

export const SET_LASTDATA = "SET_LASTDATA"
export const SET_POLLING_INTERVAL = "SET_POLLING_INTERVAL"
export const CLEAR_POLLING_INTERVAL = "CLEAR_POLLING_INTERVAL"
export const SET_HISTORY_POLLING_INTERVAL = "SET_HISTORY_POLLING_INTERVAL"
export const CLEAR_HISTORY_POLLING_INTERVAL = "CLEAR_HISTORY_POLLING_INTERVAL"

export const ADD_DATAHISTORY = "ADD_DATAHISTORY"
export const REMOVE_DATAHISTORY = "REMOVE_DATAHISTORY"

export const SET_CONTROLLERS = "SET_CONTROLLERS"

export const SET_USERNAME = "SET_USERNAME"

//USERS
export const SET_COMPANIES = "SET_COMPANIES"
export const SET_ADMINS = "SET_ADMINS"
export const SET_ACTIVEUSERID = "SET_ACTIVEUSERID"

//ALARMS
export const SET_RULES = "SET_RULES"