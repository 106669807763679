import { Module } from "vuex";
import { State } from "..";
import { FETCH_CONTROLLERS, FETCH_DATA, FETCH_DATA_HISTORY, START_POLLING, STOP_POLLING, DOWNLOAD_CSV, FETCH_BATCH_DATA, START_HISTORY_POLLING, STOP_HISTORY_POLLING } from "../action-types";
import http from "@/http";
import { SET_LASTDATA, ADD_DATAHISTORY, SET_CONTROLLERS, REMOVE_DATAHISTORY, SET_POLLING_INTERVAL, CLEAR_POLLING_INTERVAL, SET_HISTORY_POLLING_INTERVAL, CLEAR_HISTORY_POLLING_INTERVAL, RESET_METRICS } from "../mutation-types";
import IValueField from "@/interfaces/charts/IValueField";
import IChartData from "@/interfaces/charts/IChartData";
import IChartDataHist from "@/interfaces/charts/IChartDataHist";
import IChart from "@/interfaces/charts/IChart";
import IHistoryParams from "@/interfaces/charts/IHistoryParams";
import IFieldList from "@/interfaces/IFieldList";
import { NotificationType, createNotification } from "@/util/ToastNotification";
import { DataType } from "@/enum/DataType";

const POLLING_INTERVAL_MS = 5000

export interface MetricsState {
    controllers: string[]
    lastDbData: lastDataObject[]
    dataHist: Map<string, IChartDataHist[]>,
    pollingInterval: number | undefined,
    historyPollingInterval: Map<string, number>
}

interface dataHistoryPayload {
    chartId: string,
    data: IChartDataHist[]
}

interface lastDataObject {
    controllerId: string,
    data: {
        [x: string]: string
    },
    timestamp: Date
}

interface metricsDto {
    //TODO ver tipo
    timestamp: string,
    data: {
        [x: string]: string
    }
}

function parseValue(variable: string, value: string): string | number | bigint | boolean {
    variable = variable.toLowerCase()

    if (variable.startsWith(DataType.INTEGER) 
        || variable.startsWith(DataType.DOUBLE_INT)
        || variable.startsWith(DataType.UNSIGNED_INT)
        || variable.startsWith(DataType.STATE_INT)) {
        return parseInt(value)
    } else if (variable.startsWith(DataType.UNSIGNED_DOUBLE_INT)) {
        return BigInt(value)
    } else if (variable.startsWith(DataType.REAL)) {
        return parseFloat(value)
    } else if (variable.startsWith(DataType.BIT)) {
        return value === '1'
    } else {
        // s, es
        return value
    }
}

function metricsDtoToDataHist(metricsDtos: metricsDto[], vfs: IValueField[]): IChartDataHist[] {
    const response: IChartDataHist[] = []

    vfs.forEach(vf => {
        response.push({
            name: vf.name,
            values: metricsDtos.map(data => {
                return {
                    timestamp: data.timestamp,
                    value: data.data[vf.variable]
                }
            })
        } as IChartDataHist)
    })

    return response
}

const getDefaultState = () => {
    return {
        controllers: [],
        lastDbData: [],
        dataHistRequests: [],
        dataHist: new Map<string, IChartDataHist[]>(),
        pollingInterval: undefined, // Stores the interval ID
        historyPollingInterval: new Map<string, number>()
    }
}

export const metrics: Module<MetricsState, State> = {
    state: getDefaultState(),
    getters: {
        getFieldList: (state) => (dataTypes: DataType[]) : IFieldList[] => {
            const array = [] as IFieldList[]

            state.lastDbData.forEach(dataObj => {
                let fields = Object.keys(dataObj.data)

                if (dataTypes) {
                    fields = fields
                        .filter(field => 
                            dataTypes.some(dataType => 
                                field.startsWith(dataType)))
                }

                array.push({
                    controllerId: dataObj.controllerId,
                    fields: fields
                })
            })

            return array
        },
        getLastValue: (state) => (vf: IValueField): IChartData => {
            const controller = state.lastDbData.find(dataObj => dataObj.controllerId === vf.controllerId)
            let value = ''

            if (controller) {
                value = controller.data[vf.variable]
            }

            //TODO throw error?
            return {
                name: vf.name,
                value: parseValue(vf.variable, value ? value : '0')
            } as IChartData
        },
        getLastReadingTimestamp: (state): Date => {
            const dates = state.lastDbData.map(dataObj => new Date(dataObj.timestamp))
            const mappedTimes = dates.map(d => d.getTime())
            return new Date(Math.max(...mappedTimes))
        },
        getMetricsBaseUrl: (state, getters, rootState): string => {
            let baseUrl = ''

            const userId = rootState.users.activeUserId

            if (userId) {
                baseUrl += `/${userId}`
            }

            return `${baseUrl}/metrics`
        }
    },
    mutations: {
        [RESET_METRICS](state) {
            //Clear all intervals
            clearInterval(state.pollingInterval)
            state.historyPollingInterval.forEach((value, key) => clearInterval(value))
            //Reset state
            Object.assign(state, getDefaultState())
        },
        [SET_CONTROLLERS](state, controllers: string[]) {
            state.controllers = controllers
        },
        [SET_LASTDATA](state, payload: lastDataObject[]) {
            state.lastDbData = payload
        },
        [SET_POLLING_INTERVAL](state, intervalId: number) {
            state.pollingInterval = intervalId
        },
        [CLEAR_POLLING_INTERVAL](state) {
            clearInterval(state.pollingInterval)
            state.pollingInterval = undefined
        },
        [SET_HISTORY_POLLING_INTERVAL](state, {chartId, intervalId}) {
            state.historyPollingInterval.set(chartId, intervalId)
        },
        [CLEAR_HISTORY_POLLING_INTERVAL](state, chartId) {
            clearInterval(state.historyPollingInterval.get(chartId))
            state.historyPollingInterval.delete(chartId)
        },
        [ADD_DATAHISTORY](state, payload: dataHistoryPayload) {
            state.dataHist.set(payload.chartId, payload.data)
        },
        [REMOVE_DATAHISTORY](state, chart: IChart<any>) {
            state.dataHist.delete(chart.id)
        }
    },
    actions: {
        [FETCH_DATA]({ commit, state, getters }) {
            const baseUrl = getters.getMetricsBaseUrl
            const promises: Promise<lastDataObject>[] = [] 

            state.controllers.forEach(controller => {
                promises.push(
                    http.get(`${baseUrl}/${controller}/last-data`)
                        .then(resp => {
                            let data = {}
                            let timestamp = new Date()

                            if (resp.data.length > 0) {
                                data = resp.data[0].data
                                timestamp = new Date(resp.data[0].timestamp)
                            }

                            return {
                                controllerId: controller,
                                data: data,
                                timestamp: timestamp
                            }
                        })
                )

                Promise
                    .allSettled(promises)
                    .then(results => {
                        const isFulfilled = <T>(input: PromiseSettledResult<T>): input is PromiseFulfilledResult<T> => 
                            input.status === 'fulfilled'

                        const values = results
                            .filter(isFulfilled)
                            .map(result => result.value)
    
                        if (values.length > 0) {
                            commit(SET_LASTDATA, values)
                        }
                    })
            })
        },
        [FETCH_DATA_HISTORY]({ commit, getters }, chart: IChart<IHistoryParams>) {
            const baseUrl = getters.getMetricsBaseUrl
            const promises: Promise<IChartDataHist>[] = []

            chart.valueFields.forEach(vf => {
                if (vf.controllerId.trim().length === 0 
                || vf.variable.trim().length === 0) return

                let uri = `${baseUrl}/${vf.controllerId}?vars=${vf.variable}`
                uri += `&dateFrom=${chart.params.dateFrom}&dateTo=${chart.params.dateTo}`

                if (chart.params.direction != undefined) {
                    uri += `&direction=${chart.params.direction.toUpperCase()}`
                }

                if (chart.params.page != undefined) {
                    uri += `&page=${chart.params.page}`
                }

                if (chart.params.size != undefined) {
                    uri += `&size=${chart.params.size}`
                }


                promises.push(
                    http.get(uri)
                        .then(resp => {
                            return {
                                name: vf.name,
                                values: resp.data.content.map((data: metricsDto) => {
                                        return {
                                            timestamp: data.timestamp,
                                            value: data.data[vf.variable]
                                        }
                                }),
                                pageNumber: resp.data.page.number,
                                totalPages: resp.data.page.totalPages,
                                totalElements: resp.data.page.totalElements,
                                pageSize: resp.data.page.size
                            } as IChartDataHist
                        })
                )
            })

            Promise.allSettled(promises)
                .then(results => {
                    const isFulfilled = <T>(input: PromiseSettledResult<T>): input is PromiseFulfilledResult<T> => 
                    input.status === 'fulfilled'

                    const values = results
                        .filter(isFulfilled)
                        .map(result => result.value)

                    if (values.length < 1) return

                    commit(ADD_DATAHISTORY, {
                        chartId: chart.id,
                        data: values
                    } as dataHistoryPayload)
                })
        },
        [FETCH_BATCH_DATA]({ commit, getters }, chart: IChart<IHistoryParams>) {
            const baseUrl = getters.getMetricsBaseUrl

            if (chart.valueFields.length == 0) return

            const variables = chart.valueFields.map(vf => vf.variable)
            const controllerId = chart.valueFields[0].controllerId

            http
                .get(`${baseUrl}/${controllerId}/current-batch?vars=${variables.join(",")}`)
                .then(resp => 
                    commit(ADD_DATAHISTORY, {
                        chartId: chart.id,
                        data: metricsDtoToDataHist(resp.data, chart.valueFields)
                    } as dataHistoryPayload))
                //TODO mostrar erro?
                .catch(erro => erro)
        },
        [FETCH_CONTROLLERS]({ commit, getters }) {
            const baseUrl = getters.getMetricsBaseUrl

            return http.get(`${baseUrl}/controllers`)
                    .then(resp => commit(SET_CONTROLLERS, resp.data))
        },
        [START_POLLING]({ commit, state, dispatch }) {
            //If it's already running
            if (state.pollingInterval) return

            dispatch(FETCH_CONTROLLERS)
                .then(() => {
                    //Start immediatly
                    dispatch(FETCH_DATA)

                    const intervalId = setInterval(() => {
                        dispatch(FETCH_DATA)
                    }, POLLING_INTERVAL_MS)

                    commit(SET_POLLING_INTERVAL, intervalId)
                })
                .catch(() => createNotification(NotificationType.ERROR,
                    'Não foi possível comunicar com o servidor'))
        },
        [STOP_POLLING]({ state, commit }) {
            if (!state.pollingInterval) return

            commit(CLEAR_POLLING_INTERVAL)
        },
        [START_HISTORY_POLLING]({ commit, state, dispatch }, 
            chart: IChart<IHistoryParams>) {

            //If it's already running
            if (state.historyPollingInterval.has(chart.id)) return

            //Start immediatly
            dispatch(FETCH_BATCH_DATA, chart)

            const intervalId = setInterval(() => {
                dispatch(FETCH_BATCH_DATA, chart)
            }, POLLING_INTERVAL_MS)

            commit(SET_HISTORY_POLLING_INTERVAL, { 
                chartId: chart.id,
                intervalId: intervalId })
        },
        [STOP_HISTORY_POLLING]({ state, commit }, chart: IChart<IHistoryParams>) {
            if (!state.historyPollingInterval.has(chart.id)) return

            commit(CLEAR_HISTORY_POLLING_INTERVAL, chart.id)
        },
        [DOWNLOAD_CSV]({ getters }, chart: IChart<IHistoryParams>) {
            const baseUrl = getters.getMetricsBaseUrl

            if (chart.valueFields.length == 0) {
                createNotification(
                    NotificationType.ERROR, 
                    "O gráfico não possui nenhum campo selecionado")
                return
            }

            const controllerId = chart.valueFields[0].controllerId

            const moreThanOneController 
                = chart.valueFields.some(vf => vf.controllerId != controllerId)

            if (moreThanOneController) {
                createNotification(
                    NotificationType.ERROR, 
                    "Não é possível fazer download de gráficos de mais de um controlador")
                return
            }

            const variables = chart.valueFields.map(vf => vf.variable)
            const names = chart.valueFields.map(vf => vf.name)

            let uri = `${baseUrl}/${controllerId}/download?vars=${variables}&names=${names}`
            uri += `&dateFrom=${chart.params.dateFrom}&dateTo=${chart.params.dateTo}`

            if (chart.params.direction != undefined) {
                uri += `&direction=${chart.params.direction.toUpperCase()}`
            }

            return http
                    .get(uri, {
                        responseType: 'blob',
                        headers: {'Accept': 'text/csv'}
                    })
                    .then(resp => new Blob([resp.data], { type: 'text/csv' }))
        }
    }
}
